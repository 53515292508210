/*********************/
/* Commenting System */
/*********************/
form > span
{
	display: flex;
}
form > span > label
{
	flex		: 2;
	
	margin		: 5px;
	padding		: 5px;
	
	text-align	: right;
}
form > span > input
{
	flex: 8;
}
form > span > div /*textarea*/
{
	display			: flex;
	flex-direction	: column;
	flex			: 8;
	
	min-height		: 10em;
	
	margin			: 0;
	padding			: 0;
	resize: vertical;
}
form > span > div > textarea
{
	flex: 8;
	
	margin-left: 4px;
	
	resize: vertical;
}

input:not([type=submit]), textarea
{
	margin			: 5px 8px;
	padding			: 5px 8px;
	
	background		: rgba(255, 255, 255, 0.5);
	border			: 0;
	border-radius	: 5px;
	
	color			: white;
	font-size		: 1em;
	font-family		: sans-serif;
}
input::placeholder, textarea::placeholder	{ color: #eeeeee; }

.meter
{
	height: 0.5rem;
	margin: 0.3rem;
	
	background: rgba(255, 255, 255, 0.2);
	border-radius: 0.3rem;
	
	overflow: hidden;
}

.meter div
{
	height: 0.5rem;
	
	background: rgba(255, 255, 255, 0.8);
}

aside .comment
{
	margin			: 8px;
	padding			: 8px;
	
	background		: rgba(220, 196, 255, 0.18);
	border-radius	: 5px;
}

aside .comment h4
{
	margin: 0 5px;
	text-align: left;
}

aside .comment .body
{
	margin: 5px;
}

aside .comment time, aside .comment .permalink
{
	margin		: 5px;
	
	font-size	: small;
	color		: rgba(255, 255, 255, 0.7);
}

aside .comment:target
{
	background: rgba(255, 255, 255, 0.5);
}

aside .comment h4:before
{
	margin: 5px;
	
	counter-increment: comment;
	content: "#" counter(comment)/* " | " attr(data-comment-id)*/;
	
	float: right;
	
	color: rgba(255, 255, 255, 0.4);
	font-weight: normal;
	font-size: x-small;
}

.avatar
{
	border-radius: 0.3rem;
}
